import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Site under construction <br />
          Notary Services, Inc. <br />
          Phone Number: +1 (760) 284-5669
        </p>
      </header>
    </div>
  );
}

export default App;
